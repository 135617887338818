<template>
  <div
    class="bg-center bg-no-repeat bg-cover"
    style="background-image: url('/images/main-banner.jpg')"
  >
    <div class="container pt-32 pb-10 md:pt-40 md:pb-20 lg:pt-52 lg:pb-40">
      <h1 class="text-3xl text-gray-800 md:text-4xl lg:text-5xl">
        Your Partner <br />
        In
        <span class="font-medium text-ting-litegreen">Smile Perfection</span>
      </h1>
      <div class="bg-ting-litegreen h-0.5 w-24 my-5"></div>
      <div v-if="!hideEnquiry" class="flex flex-wrap">
        <!-- <h1
          class="w-32 p-2 m-1 text-center text-white transition duration-150 ease-linear rounded-lg md:mr-1 bg-ting-litegreen hover:bg-ting-mediumgreen"
        >
          <a href="tel:+60102863168">Call Us Now</a>
        </h1> -->
        <h1
          class="w-32 p-2 m-1 text-center text-white transition duration-150 ease-linear rounded-lg bg-ting-litegreen hover:bg-ting-mediumgreen"
        >
          <a href="https://wa.me/60102863168">Whatsapp Us</a>
        </h1>
        <h1
          class="w-32 p-2 m-1 text-center text-white transition duration-150 ease-linear rounded-lg bg-ting-litegreen hover:bg-ting-mediumgreen"
        >
          <a href="https://goo.gl/maps/qYn6fCr3R9qyEDLN8">Locate Us</a>
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hideEnquiry: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
</style>