<template>
  <div class="home">
    <WaBtn />
    <img src="/images/bg-header.jpg" alt="" class="" />
    <header class="py-3 text-center">
      <div class="container md:py-4">
        <div class="md:flex md:justify-between md:items-center">
          <img
            src="/images/main-logo.png"
            alt=""
            class="w-3/4 mx-auto md:mx-0 md:w-60"
          />
          <div class="pt-6 text-lg font-medium md:text-base md:pt-0">
            <h1
              class="w-3/4 p-2 mx-auto text-gray-600 border-2 md:w-60 border-ting-litegreen"
            >
              Call Us :
              <a
                href="tel:+60102863168"
                class="transition duration-300 ease-linear hover:text-ting-litegreen"
                >+6010-2863168</a
              >
            </h1>
          </div>
        </div>
      </div>
    </header>
    <!-- main banner  -->
    <MainBanner />
    <!-- tagline  -->
    <div class="bg-ting-litegreen">
      <div class="container py-6">
        <p
          class="pb-3 text-xs text-center text-white lg:mx-auto lg:max-w-3xl md:text-base"
        >
          We provide fully-customised treatment plans to address all your dental
          needs. It is our desire to bring you perfect and healthy smiles. Do
          drop by for a visit, you’ll be pleasantly surprised!
        </p>
      </div>
    </div>

    <!-- about us -->
    <div class="py-10">
      <div class="container lg:flex lg:items-center">
        <div class="pb-4 lg:w-1/2 lg:pr-4 lg:pb-10">
          <div class="text-center lg:text-left">
            <h1 class="text-gray-600">About us</h1>
            <h1 class="text-2xl font-medium text-ting-mediumgreen">
              JA Dental Surgery
            </h1>
          </div>
          <div class="pt-6 text-sm font-light">
            <p>
              We are a team of passionate dental professionals who strive to
              provide quality dental treatment for you and your family in a warm
              and relaxing environment. We always treat each patient not as a
              set of teeth, but as an individual, whose concerns will be heard
              with patience, providing ample opportunity to ask questions and
              tailoring treatment options to meet their unique needs. We look
              forward to be your trusted wellness partner in many years to come.
            </p>
          </div>
        </div>
        <img src="/images/bg-dental.jpg" alt="" class="lg:w-1/2 lg:block" />
      </div>
    </div>

    <!-- product and services  -->
    <div
      class="py-10 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/bg-service.jpg')"
    >
      <div class="container">
        <div>
          <div class="text-center">
            <h1 class="text-gray-600">Our Services</h1>
            <h1 class="text-2xl font-medium text-ting-mediumgreen">
              One-Stop Dental Services
            </h1>
          </div>
          <p
            class="pt-5 text-sm text-center text-gray-600 lg:max-w-3xl lg:mx-auto"
          >
            Our team provides one-stop dental services for adults and children.
            Check out our range of dental services for you and your loved ones.
          </p>
          <div class="flex flex-wrap pt-8 lg:justify-center">
            <div
              v-for="(item, i) in services"
              :key="i"
              class="w-1/2 p-1 md:w-1/4 lg:w-1/5"
            >
              <div
                class="py-5 transition duration-300 ease-in-out rounded-lg md:px-1 bg-ting-mediumgreen hover:bg-ting-litegreen"
              >
                <img :src="item.image" :alt="item.alt" class="w-20 mx-auto" />
                <h1
                  v-html="item.alt"
                  class="pt-4 text-sm leading-tight text-center text-white xl:text-base h-14"
                >
                  {{ item.alt }}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- promotion  -->
    <!-- <div class="py-10 bg-gray-50">
      <div class="container">
        <div class="text-center">
          <h1 class="text-gray-600">JA Dental</h1>
          <h1 class="text-2xl font-medium text-ting-mediumgreen">
            Special Offers
          </h1>
        </div>

        <div class="pt-8 md:flex md:flex-wrap lg:max-w-4xl lg:mx-auto">
          <div
            v-for="(item, i) in promotion"
            :key="i"
            class="pb-3 md:px-1 md:w-1/2"
          >
            <div class="border rounded border-ting-mediumgreen">
              <h1
                class="py-4 text-xl font-medium text-center text-white bg-ting-mediumgreen"
              >
                {{ item.alt }}
              </h1>
              <img :src="item.image" :alt="item.alt" />
              <div class="py-4 space-y-1 text-center">
                <p v-for="list in item.info" :key="list" class="">
                  <span class="font-bold text-red-900">FREE</span> {{ list }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="pt-3">
          <h1
            class="p-2 mx-auto text-white transition duration-300 ease-linear transform rounded-lg hover:scale-110 bg-wa-green w-36"
          >
            <a href="https://wa.me/60102863168">
              <i class="pr-1 fa-brands fa-whatsapp"></i>Whatsapp Us
            </a>
          </h1>
        </div>
      </div>
    </div> -->

    <!-- meet our doctor  -->
    <div class="py-10">
      <div class="container">
        <div class="text-center">
          <h1 class="text-gray-600">Meet</h1>
          <h1 class="text-2xl font-medium text-ting-mediumgreen">
            Our Dentists
          </h1>
        </div>

        <div class="pt-5 md:flex md:flex-wrap">
          <div
            v-for="(item, i) in doctor"
            :key="i"
            class="w-2/3 pb-2 mx-auto md:p-2 md:w-1/2 lg:w-1/4"
          >
            <div class="p-3 shadow-md">
              <img :src="item.image" :alt="item.alt" class="mx-auto" />
              <div class="py-3 leading-tight text-center">
                <h1 class="text-lg font-medium text-ting-litegreen">
                  {{ item.alt }}
                </h1>
                <p class="text-sm text-gray-600 lg:h-10">{{ item.p }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- why choose us -->
    <div class="pb-10">
      <div class="container">
        <div class="text-center">
          <h1 class="text-gray-600">Why</h1>
          <h1 class="text-2xl font-medium text-ting-mediumgreen">Choose Us</h1>
        </div>
        <div class="flex flex-wrap pt-6 md:justify-center">
          <div
            v-for="(item, i) in choose"
            :key="i"
            class="w-1/2 p-2 text-center md:w-1/4"
          >
            <img :src="item.image" :alt="item.alt" class="w-24 mx-auto" />
            <h1 class="pt-4 text-sm leading-tight">{{ item.alt }}</h1>
          </div>
        </div>
      </div>
    </div>

    <!-- testimonials -->
    <div
      class="py-10 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/bg-testimonial.jpg')"
    >
      <div class="container">
        <div class="text-center">
          <h1 class="text-white">Our</h1>
          <h1 class="text-2xl font-medium text-white">Testimonials</h1>
        </div>
        <div>
          <carousel
            :autoplay="true"
            :loop="true"
            :responsive="{
              0: { items: 1, nav: false, dots: true },
            }"
          >
            <div
              v-for="(item, i) in testimonial"
              :key="i"
              class="pt-10 text-center"
            >
              <p class="text-base font-normal leading-tight text-white">
                {{ item.text }}
              </p>
              <h1 class="pt-6 text-lg font-bold text-white">
                {{ item.client }}
              </h1>
            </div>
          </carousel>
        </div>
      </div>
    </div>

    <!-- faq -->
    <div class="py-10">
      <div class="container">
        <div class="text-center">
          <h1 class="text-gray-600">Frequently</h1>
          <h1 class="text-2xl font-medium text-ting-mediumgreen">
            Asked Questions
          </h1>
        </div>
        <div class="w-10 h-0.5 mx-auto bg-teeth-blue"></div>
        <div class="lg:w-2/3 lg:mx-auto">
          <Accordion :faq="faq" :focus="false" class="pt-4" />
        </div>
      </div>
    </div>

    <!-- clientele  -->
    <!-- <div class="pb-10">
      <div class="container">
        <div class="text-center">
          <h1 class="text-gray-600">Our</h1>
          <h1 class="text-2xl font-medium text-ting-mediumgreen">Clienteles</h1>

          <div class="pt-5 md:flex">
            <div v-for="(item, i) in client" :key="i" class="pb-4 md:p-2 md:w-1/3">
              <img :src="item.image" :alt="item.alt" class="" />
              <h1 class="pt-4 text-sm leading-tight text-gray-600">
                {{ item.alt }}
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <!-- enquire form  -->
    <div class="py-10 bg-ting-litegreen">
      <div class="container">
        <div id="element">
          <h1 class="pb-4 text-xl font-medium text-center text-white">
            Send Us An Enquiry
          </h1>
          <EnquiryForm class="lg:max-w-3xl lg:mx-auto" />
        </div>
      </div>
    </div>

    <footer class="py-10">
      <div class="container">
        <div class="lg:flex">
          <div class="text-center lg:w-1/2">
            <img
              src="/images/main-logo.png"
              alt=""
              class="w-4/5 mx-auto md:w-56"
            />
            <h1 class="pt-4 text-ting-mediumgreen">
              NO. 149G , JALAN SS2/6, 47300 PETALING JAYA, SELANGOR.
            </h1>
            <div class="pt-4">
              <h1>Consultation Hours:</h1>
              <p class="text-sm text-gray-600">
                Mon - Sat: 9.00am - 6.00pm <br />
                Sun: 9.00am - 4.00pm <br />
                Closed On Selected Public Holidays
              </p>
              <h1 class="pt-3 text-gray-600">
                Call Us At
                <a
                  href="tel:+60102863168"
                  class="transition duration-300 ease-linear hover:text-ting-mediumgreen"
                  >010-2863168</a
                >
              </h1>
            </div>
          </div>
          <div class="pt-5 lg:pt-0 lg:w-1/2">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3983.9171425107456!2d101.6170703!3d3.1166207999999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc4912cdcaf9ad%3A0x865b34f8a850df57!2sJA%20DENTAL%20SURGERY!5e0!3m2!1sen!2smy!4v1653461716083!5m2!1sen!2smy"
              width="100%"
              height="350"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </footer>

    <!-- Copyright -->
    <div class="py-3 bg-ting-mediumgreen">
      <div class="container mx-auto">
        <h1 class="text-xs text-center text-white lg:text-sm">
          Website maintained by Activa Media. All Rights Reserved.
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import carousel from "vue-owl-carousel";
import Accordion from "@/components/Accordion.vue";
import EnquiryForm from "@/components/EnquiryForm.vue";
import MainBanner from "@/components/MainBanner.vue";
import WaBtn from "@/components/WaBtn.vue";

export default {
  name: "Home",
  components: {
    carousel,
    Accordion,
    EnquiryForm,
    MainBanner,
    WaBtn,
  },
  data() {
    return {
      services: [
        {
          image: "/images/dental-1.png",
          alt: "Air-Flow Dental Spa<br>(Painless teeth cleaning)",
        },
        { image: "/images/dental-4.png", alt: "Dental Implants" },
        { image: "/images/dental-3.png", alt: "Invisalign" },
        { image: "/images/dental-2.png", alt: "Braces" },
        { image: "/images/dental-5.png", alt: "Root Canal Treatment" },
        { image: "/images/dental-6.png", alt: "Crowns and Bridges" },
        { image: "/images/dental-7.png", alt: "Veneers and Smile Design" },
        { image: "/images/dental-8.png", alt: "Whitening" },
        { image: "/images/dental-17.png", alt: "Wisdom Teeth Surgery" },
        { image: "/images/dental-9.png", alt: "Dentures" },
        { image: "/images/dental-11.png", alt: "Fillings" },
        { image: "/images/dental-14.png", alt: "Kids Dentistry" },

        // { image: "/images/dental-10.png", alt: "Oral Surgery" },
        // { image: "/images/dental-12.png", alt: "Gum Treatment" },
        // { image: "/images/dental-13.png", alt: "Laser Dentistry" },
        // { image: "/images/dental-15.png", alt: "Dental X-Ray" },
        // { image: "/images/dental-16.png", alt: "Preventive Dentistry" },
      ],
      doctor: [
        {
          image: "/images/doctor-1.jpg",
          alt: "Dr. Ting Diew Leong",
          p: "BDS (Malaya)",
        },
        {
          image: "/images/doctor-2.jpg",
          alt: "Dr. Ting Ann Nee",
          p: "BDS (Malaya), Fellowship (ICCDE)",
        },
        {
          image: "/images/doctor-3.jpg",
          alt: "Dr. Koo Sien Kai",
          p: "DDS (Lincoln)",
        },
        {
          image: "/images/doctor-4.jpg",
          alt: "Dr. Chong Wei Ning",
          p: "BDS (AIMST)",
        },
      ],
      choose: [
        {
          image: "/images/choose-1.png",
          alt: "Professional and certified dentists",
        },
        {
          image: "/images/choose-2.png",
          alt: "High quality treatment and services",
        },
        {
          image: "/images/choose-3.png",
          alt: "Cosy and comfortable environment",
        },
        { image: "/images/choose-5.png", alt: "Cutting-edge technology" },
        {
          image: "/images/choose-6.png",
          alt: " Conveniently located at SS2, Petaling Jaya",
        },
        { image: "/images/choose-7.png", alt: "Operating 7 days a week" },
        // { image: "/images/choose-4.png", alt: "Personalised dental care" },
      ],
      testimonial: [
        {
          text: "Dentist is super patient and recommended only what is needed. Non pushy. And filling services are very lasting unlike some other dentist. Reception is super friendly too. Price is very reasonable. Highly recommended",
          client: "Caroline Lim",
        },
        {
          text: "I’ve been here for tooth extraction and root canal, Dr. Ting is very patient and understanding. Environment is comfortable and clean, nurse are friendly. Recommended dentist around the neighbourhood. They also send reminder for your appointment, which is a plus effort. ",
          client: " Jeanee Lee",
        },
        {
          text: "Felt very fresh after done scaling… Great job from the dentist.. No pain.. The dentist is very gentle and always keep the patient comfortable. Also constantly remind the patient on the progress.. Definitely will come back.. ",
          client: "LVHONG Lim",
        },
        {
          text: "I am very satisfied with the service here. The staff was friendly and Dr Ting is very professional. Most importantly she is very gentle so treatment here is comfortable, I would highly recommend her to everyone. ",
          client: "Lim Siew Woon",
        },
      ],
      faq: [
        {
          q: "What is Air-Flow Dental Spa?",
          a: "Modern, minimally invasive scaling and polishing that ensures efficient plaque and calculus removal. 100% of our patients say that this is more comfortable and less sensitive than conventional teeth cleaning. It’s basically a spa session for your teeth!",
        },
        {
          q: "Am I too old for braces?",
          a: "No. Braces are effective for both children and adults of all ages. Once a child has reached a certain level of teeth development, our dentists will advise parents accordingly for their child’s/children’s braces treatment.",
        },
        {
          q: "How long does the entire braces treatment take to complete?",
          a: "The total duration of treatment depends on the complexity of your oral condition. Typically, the entire process of getting braces can last between 9 to 24 months. Your dentist will be able to provide you with more details during your consultation.",
        },
        {
          q: "How long can dental implants last?",
          a: "Dental implants, when planned and placed precisely, will last you a lifetime. The only part of the implant that requires maintenance would be the abutment screw and porcelain crown due to wear and tear.",
        },
        {
          q: "How painful are dental implants?",
          a: "Most people who have received dental implants say that there is very little discomfort involved in the procedure. Local anaesthesia is most commonly used during the procedure, and most patients report that implants involve less pain than a tooth extraction.",
        },
        {
          q: "How do I care for dental implants?",
          a: "Dental implants require the same care as your natural teeth, which includes brushing, flossing, rinsing with an antibacterial mouthwash, and regular dental check-ups.",
        },
        {
          q: "Is root canal treatment painful?",
          a: "No. In fact, the treatment helps to alleviate pain. The pain that is felt usually comes from the inflammation and swelling caused by the infection in your tooth. During your root canal treatment, your dentist will remove infected tissues and treat the infected area with antibacterial medication before sealing the cavity with filling. Some may experience discomfort after the treatment, but this should subside after a few days as the infected area recovers. However, you should consult your dentist if the pain or swelling persists.",
        },
      ],
      client: [
        {
          image: "/images/client-1.jpg",
          alt: "We serve the community in Petaling Jaya and Kuala Lumpur 7 days a week (Adults & kids)",
        },
        {
          image: "/images/client-2.jpg",
          alt: "Teenagers and working adults (Braces & Invisalign)",
        },
        {
          image: "/images/client-3.jpg",
          alt: " Adults and elderlies (implants)",
        },
      ],
      promotion: [
        {
          alt: "Implant",
          image: "/images/promo-1.jpg",
          info: [
            "Consultation",
            "Dental X-Ray",
            "Teeth Molds",
            "All Intraoral Photos",
            "All Extraoral Photos",
          ],
        },
        {
          alt: "Braces",
          image: "/images/promo-2.jpg",
          info: [
            "Consultation",
            "Dental X-Ray",
            "Teeth Molds",
            "All Intraoral Photos",
            "All Extraoral Photos",
          ],
        },
      ],
    };
  },
};
</script>
